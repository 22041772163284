import Link from "next/link";

type ErrorsLayoutProps = {
    message: string
}
export default function ErrorsLayout({message}: ErrorsLayoutProps): JSX.Element {
    return (
        <div className="bg-black flex flex-col gap-5 justify-center w-[100vw] h-[100vh] min-w-[1100px] items-center"
            style={{
                backgroundImage: "url(img/pattern-custom.png)",
                backgroundSize: "50px 50px"
            }}>
            <div className="w-24 h-24 rounded-full bg-white border-gray-400 border-[5px]">
                <img className="p-3 h-full w-full" src="/img/favicon.svg" alt="Epicore logo" />
            </div>
            <h1 className="text-gray-400 text-lg">{message}</h1>
            <Link href="/">
                <button
                    className="btn btn-sm btn-primary font-normal normal-case mt-4 px-8">Home
                </button>
            </Link>
        </div>
    );
}
